(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/banner-grid/views/index.js') >= 0) return;  svs.modules.push('/widgets/banner-grid/views/index.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.widget=_svs.widget||{};
_svs.widget.banner_grid=_svs.widget.banner_grid||{};
_svs.widget.banner_grid.templates=_svs.widget.banner_grid.templates||{};
svs.widget.banner_grid.templates.index = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = ((helper = (helper = lookupProperty(helpers,"bannerGroupHtml") || (depth0 != null ? lookupProperty(depth0,"bannerGroupHtml") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"bannerGroupHtml","hash":{},"data":data,"loc":{"start":{"line":1,"column":23},"end":{"line":1,"column":44}}}) : helper))) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"currentWidget") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":53}}})) != null ? stack1 : "")
    + "\n";
},"useData":true});
Handlebars.partials['widget-banner_grid-index'] = svs.widget.banner_grid.templates.index;
})(svs, Handlebars);


 })(window);